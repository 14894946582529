<template>
  <div class="chargingInfoList">
    <!-- 顶部导航 -->
    <commonNavBar :title="'施工协议计费明细'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <!-- 计费明细列表 -->
    <van-collapse
      v-model="activeOpeNames"
      accordion
    >
      <van-collapse-item
        :title="item"
        v-for="(item, index) in Object.keys(chargingInfoList)"
        :key="index"
        :class="{ hasChange: chargingInfoList[item].hasChange === 1 }"
      >
        <van-cell
          center
          title="服务费用:"
          :value="chargingInfoList[item].serverCost | moneyFormat"
        >
        </van-cell>
        <van-cell
          center
          title="是否变更:"
        >
          <template #default>
            <span
              style="color: #d9001b"
              v-if="chargingInfoList[item].hasChange === 1
              "
            >是</span>
            <span
              style="color: #00a854"
              v-else-if="chargingInfoList[item].hasChange === 0
              "
            >否</span>
            <span v-else>--</span>
          </template>
        </van-cell>
        <van-cell
          center
          title="变更原因:"
          :value="chargingInfoList[item].changeReason?.toString() || '--'"
        >
        </van-cell>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { getconstructionChargingInfoList } from '@/api/constructionStandard'
import { mapState } from 'vuex'

export default {
  name: 'chargingInfoList',
  data() {
    return {
      activeOpeNames: 0,
      loadingShow: false,
      chargingInfoList: {}
    }
  },
  computed: {
    ...mapState('chargingInfoList', ['chargingInfo'])
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getconstructionChargingInfoList(this.$route.params.id)
      if (data.data) {
        this.chargingInfoList = { ...data.data }
      }
      this.loadingShow = false
    } catch (error) {
      this.loadingShow = false
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chargingInfoList {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .hasChange {
      >.van-cell {
        background-color: #facd91;
      }
    }
  }
}
</style>
